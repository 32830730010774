const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selected: null,
  middlewares: {},
  logs: [],
  response: null,
  usage: {}
}

const reducer = (state = initialState, shortcut) => {
  switch (shortcut.type) {
    case 'CLEAR_RESPONSE':
      return { 
        ...state,
        response: null 
      }        
    case 'GET_SHORTCUT_DATA':
      return {
        ...state,
        allData: shortcut.allData,
        data: shortcut.data,
        total: shortcut.totalPages,
        params: shortcut.params
      }
    case 'NEW_SHORTCUT':
      return { 
        ...state,
        middlewares: shortcut.middlewares,
      }    
    case 'CREATE_SHORTCUT':
      return { 
        ...state,
        response: shortcut.response 
      }            
    case 'EDIT_SHORTCUT':
      return { 
        ...state, 
        selected: shortcut.selected,
        middlewares: shortcut.middlewares,
      }
    case 'UPDATE_SHORTCUT':
      return { 
        ...state,
        response: shortcut.response 
      }    
    case 'UPDATE_SHORTCUT_ENTRY':
      return { 
        ...state,
        response: shortcut.response,
        selected: shortcut.selected,
      }         
    case 'GET_SHORTCUT_LOGS':
      return { 
        ...state, 
        logs: shortcut.logs,
        total: shortcut.totalPages,
        params: shortcut.params        
      }   
    case 'GET_SHORTCUT_USAGE':
      return { 
        ...state, 
        usage: shortcut.usage,
        total: shortcut.totalPages,
        params: shortcut.params        
      }            
    case 'DELETE_SHORTCUT':
      return { 
        ...state,  
        response: shortcut.response
      }
    default:
      return { ...state }
  }
}
export default reducer