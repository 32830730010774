const initialState = {
  statistics: [],
  middlewares: [],
  logs: [],
  response: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_RESPONSE':
      return { 
        ...state,
        response: null 
      }
    case 'GET_ACCOUNT_DASHBOARD':
      return { 
        ...state, 
        statistics: action.data,
      }
    default:
      return { ...state }
  }
}
export default reducer